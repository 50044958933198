import React, { useState, useEffect, useRef } from 'react'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { Link, withRouter } from 'react-router-dom'
import Moment from 'react-moment'
import 'moment-timezone'
import {
  Button,
  Input,
  Col,
  Label,
  FormGroup,
  Container,
  Row,
} from 'reactstrap'
import Switch from "react-switch";
import Axios from 'axios'
import DateUtil from '../../lib/Dateutil'
import endpoint from '../../config/config-api'
import Swal from 'sweetalert2'

const axios = Axios.create({
  withCredentials: true,
})


var ReactBsTable = require('react-bootstrap-table')
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const List = (props) => {
  const [data, setData] = useState([])
  const [departmentdata, Setdepartmentdata] = useState('')
  const inputRef = useRef(null)
  const [department_name, Setdepartment_name] = useState('')
  const [search_job, Setsearch_job] = useState('')

  const [totalActive, setTotalActive] = useState(0)
  const [total, setTotal] = useState(0)
  const options = { sizePerPage: 25 }
  const rowindex = (cell, row, enumObject, index) => {
    return <i>{index + 1}</i>
  }

  const onChangeStatus = async (value, id) => {

    const linkApi = `${endpoint.apiTest}/v1.1/job/cms/jobAnnouncement/update-status/${id}`
    axios({
      method: 'put',
      url: linkApi,
      data: {
        job_status: value ? '1' : '2',
      },
    }).then(response => {
      const { status } = response
      if (status === 200) {
        if (search_job) {
          SearchApi()
        } else getData()
      }
    })

  }

  const onSwichStatus = (id) => {
    const obj = data.find((item) => item._id === id)
    return (
      <Switch
        key={id}
        checkedIcon={false}
        uncheckedIcon={false}
        onColor="#1cc88a"
        onChange={(e) => onChangeStatus(e, id)}
        checked={obj.job_status > 1 ? false : true} />

    )
  }

  const onLinkTitle = (row) => {
    const { _id, job_title } = row
    return (
      <Link to={'edit/' + _id} >
        {job_title}
      </Link>
    )
  }

  const selectMember = (cell) => {
    return (
      <Link to={'edit/' + cell} className="btn btn-success btn-circle btn-sm">
        <i className="fas fa-edit"></i>
      </Link>
    )
  }
  const deletebutton = (cell) => {
    return (
      <Button
        onClick={() => openWarning(cell)}
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-user-times"></i>
      </Button>
    )
  }
  const SearchApi = async () => {
    const name = department_name === 'ทั้งหมด' ? '' : department_name

    const obj = {
      job_title: search_job,
      business: name,
    }
    const res = await axios.post(
      `${endpoint.apiTest}/v1.1/job/cms/jobAnnouncement/searchByUser`,
      obj,
    )

    const newArr = []

    res.data.results.map((items, key) => {
      const newObj = {
        _id: items._id,
        business: items.business,
        contract: items.contract,
        created_time: items.created_time,
        education: items.education,
        job_closedate: DateUtil.Datenormal(items.job_closedate),
        job_duty: items.job_duty,
        job_id: items.job_id,
        job_limit: items.job_limit,
        job_opendate: DateUtil.Datenormal(items.job_opendate),
        job_requirement: items.job_requirement,
        job_status: items.job_status,
        job_title: items.job_title,
        salary: items.salary,
        updatedAt: items.updatedAt,
      }

      newArr.push(newObj)
    })
    setData(newArr)

    setTotalActive(res.data.total_active)
    setTotal(res.data.total_count)
  }

  const getData = async () => {
    const items = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/jobAnnouncement/all?v=` +
      Math.random() * 1000,
    )
    const newArr = []
    items.data.results.joblist.map((items, key) => {
      const newObj = {
        _id: items._id,
        business: items.business,
        contract: items.contract,
        created_time: items.created_time,
        education: items.education,
        job_closedate: DateUtil.DatenormalTime(items.job_closedate),
        job_duty: items.job_duty,
        job_id: items.job_id,
        job_limit: items.job_limit,
        job_opendate: DateUtil.DatenormalTime(items.job_opendate),
        job_requirement: items.job_requirement,
        job_status: parseInt(items.job_status),
        job_title: items.job_title,
        salary: items.salary,
        updatedAt: items.updatedAt,
        total_active: items.total_active,
        total_count: items.total_count,
      }

      newArr.push(newObj)
    })
    Setdepartmentdata(items.data.results.config.joblist.business)
    setData(newArr)
    setTotalActive(items.data.results.config.joblist.total_active)
    setTotal(items.data.results.config.joblist.total_count)
  }

  const convetJobStatusToText = (value) => {
    if (parseInt(value) === 1) {
      return 'เปิดรับสมัคร'
    } else {
      return 'ปิดรับสมัคร'
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const deleteData = async (id) => {
    await axios.put(
      `${endpoint.apiTest}/v1.1/job/cms/jobAnnouncement/delete/${id}`,
    )
    getData()
  }

  const dateFormatter = (cell) => {
    if (!cell) {
      return ''
    }
    return <Moment format="DD/MM/YYYY H:mm">{cell}</Moment>
  }

  const openWarning = (id) => {
    Swal.fire({
      title: '<strong><p>ยืนยันการลบ</p></strong>',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#1cc88a',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id)
      }
    })
  }

  return (
    <>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            จัดการข้อมูลประกาศสมัครงาน
          </h6>
        </div>
        <Container>
          <Row>
            <Col xs="6" sm="4">
              <FormGroup>
                <Label for="exampleSelect">ค้นหาสายงาน</Label>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  onChange={(e) => Setdepartment_name(e.target.value)}
                >
                  <option>ทั้งหมด</option>
                  {Object.values(departmentdata).map((data, index) => (
                    <option key={index}>{data.label}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="exampleSearch">ค้นหาตำแหน่ง</Label>
                <Input
                  type="search"
                  name="search"
                  id="exampleSearch"
                  onChange={(e) => Setsearch_job(e.target.value)}
                />
              </FormGroup>
              <Button onClick={() => SearchApi()} outline color="success">
                ค้นหา
              </Button>{' '}
            </Col>
          </Row>
        </Container>
        <div className="card-body">
          <br />
          <Button
            onClick={() => props.history.push('/form')}
            color="primary"
            className="custom-rigth btn btn-primary btn-sm"
          >
            เพิ่มข้อมูลรับสมัครงาน
          </Button>{' '}
          <p
            className="custom-left"
            style={{ paddingLeft: '10px'}}
          >
            เปิดสมัคร {totalActive}
          </p>
          <p
            className="custom-left"
          >
            ทั้งหมด {total}
          </p>
         {' '}
          <BootstrapTable
            data={data}
            pagination={true}
            options={options}
            className="table table-striped"
            exportCSV
          >
            <TableHeaderColumn dataField="_id" dataFormat={rowindex} width="50">
              ลำดับ
            </TableHeaderColumn>
            <TableHeaderColumn dataField="_id" width="100" tdStyle={{ 'textAlign': 'center' }} dataFormat={(cell, row) => onSwichStatus(cell)}>
              สถานะ
            </TableHeaderColumn>

            <TableHeaderColumn ref={inputRef} dataField="job_title" width="300" dataFormat={(cell, row) => onLinkTitle(row)}>
              ตำแหน่ง
            </TableHeaderColumn>
            <TableHeaderColumn ref={inputRef} dataField="business" width="300" dataFormat={(cell) => { return cell.label }}>
              สายงาน
            </TableHeaderColumn>
            <TableHeaderColumn dataField="job_limit" width="200">
              จำนวนที่รับ
            </TableHeaderColumn>

            {/* <TableHeaderColumn dataField={'job_opendate'} width="300">
              วันที่ประกาศ
            </TableHeaderColumn> */}
            {/* <TableHeaderColumn dataField="job_closedate" width="300">
              วันที่สิ้นสุดประกาศ
            </TableHeaderColumn> */}

            <TableHeaderColumn
              dataField="_id"
              width="70"
              isKey
              dataFormat={selectMember}
            >
              แก้ไข
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="_id"
              width="70"
              dataFormat={deletebutton}
            >
              ลบ
            </TableHeaderColumn>
          </BootstrapTable>

        </div>
      </div>
    </>
  )
}
export default withRouter(List) 
