import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
  Card,
  CardTitle,
  CardText,
  Nav,
  CardImg,
  CardBody,
  CardHeader,
  CardColumns,
  CardGroup,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import Axios from 'axios'
import endpoint from '../../../config/config-api'
import Swal from 'sweetalert2'

import { Gallery } from 'react-grid-gallery'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

const ReactBsTable = require('react-bootstrap-table')
const BootstrapTable = ReactBsTable.BootstrapTable
const TableHeaderColumn = ReactBsTable.TableHeaderColumn
const axios = Axios.create({ withCredentials: true })

const ActivitiesSection = ({ props }) => {
  const [dataActivities, setDataActivities] = useState()
  const [selectYear, setSelectYear] = useState(new Date().getFullYear())
  const [modal, setModal] = useState(false)
  const [imageGallery, setImageGallery] = useState([])

  const [index, setIndex] = useState(-1)

  const currentImage = imageGallery[index]
  const nextIndex = (index + 1) % imageGallery.length
  const nextImage = imageGallery[nextIndex] || currentImage
  const prevIndex = (index + imageGallery.length - 1) % imageGallery.length
  const prevImage = imageGallery[prevIndex] || currentImage

  const handleClick = (index, item) => setIndex(index)
  const handleClose = () => setIndex(-1)
  const handleMovePrev = () => setIndex(prevIndex)
  const handleMoveNext = () => setIndex(nextIndex)

  const toggle = () => setModal(!modal)
  useEffect(() => {
    fetchAcivitiesData()
  }, [])

  const fetchAcivitiesData = async (year) => {
    const response = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/activities/get?year=${
        year || new Date().getFullYear()
      }`,
    )
    if (response.status === 200) {
      const resData = response.data.data
      setDataActivities(resData)
    }
  }

  function handleSelectChangeYear(event) {
    setSelectYear(event.target.value)
    fetchAcivitiesData(event.target.value)
  }

  const titleFormatter = (cell, row) => {
    return (
      <a
        // onClick={() => props.history.push(`/panorama/form/${row._id}`)}
        style={{ color: '#4e73df', cursor: 'pointer' }}
      >
        {row.title}
      </a>
    )
  }
  const descriptionFormatter = (cell, row) => {
    return (
      <a
        // onClick={() => props.history.push(`/panorama/form/${row._id}`)}
        style={{ color: '#4e73df', cursor: 'pointer' }}
      >
        {row.description}
      </a>
    )
  }

  const imageFormatter = (cell, row) => {
    return row.imageSrc === '' || row.imageSrc === undefined ? (
      'ไม่มีภาพหน้าปก'
    ) : (
      <img
        alt="pano"
        src={ process.env.REACT_APP_WEB_MEDIA_PATH + row.imageSrc}
        width="auto"
        height="100px"
      />
    )
  }

  const viewGallery = (cell, row, enumObject, index) => {
    return (
      <Button
        color="primary"
        onClick={() => {
          toggle()
          isShowGallery(index)
        }}
      >
        ดูแกลลอรี่
      </Button>
    )
  }

  const isShowGallery = (index) => {
    let arr = []
    dataActivities[index].imageGallery.map((image) => {
      arr.push({
        src: image.url,
        width: image.width,
        height: image.height,
      })
    })

    setImageGallery(arr)
  }

  const rowindex = (cell, row, enumObject, index) => {
    return <i>{index + 1}</i>
  }

  const editActivities = (cell, row) => {
    return (
      <a
        onClick={() =>
          props.history.push(`/EditHomePage/ActivitiesForm/${row._id}`)
        }
        style={{ color: '#4e73df', cursor: 'pointer' }}
      >
        แก้ไข
      </a>
    )
  }

  const deletebutton = (cell) => {
    return (
      <Button
        onClick={() => openWarning(cell)}
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-user-times"></i>
      </Button>
    )
  }

  const deleteData = async (id) => {
    const result = await axios.delete(
      `${endpoint.apiTest}/v1.1/job/cms/activities/delete/${id}`,
    )
    if (result.status === 200) {
      fetchAcivitiesData()
    }
  }

  const openWarning = (id) => {
    Swal.fire({
      title: '<strong><p>ยืนยันการลบ</p></strong>',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#1cc88a',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id)
      }
    })
  }

  const options = {
    page: 1, // which page you want to show as default
    sizePerPage: 5, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    sizePerPageList: [], // show dropDowsn select
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
  }
  return (
    <Row>
      <div className="card-body">
        <Card>
          <CardHeader>Activities</CardHeader>
          <Nav>
            <div className="mb-6" style={{ margin: '20px' }}>
              <div className="  py-3">
                <Button
                  onClick={() =>
                    props.history.push('/EditHomePage/ActivitiesForm')
                  }
                  color="primary"
                  className="custom-rigth btn btn-primary btn-sm"
                >
                  เพิ่มข้อมูลส่วน Activities
                </Button>
              </div>
            </div>
          </Nav>
          <CardBody>
            <Row>
              <Col xs="2">
                <FormGroup>
                  <Label for="exampleSelect">ปี</Label>
                  <Input
                    type="select"
                    name="selectYear"
                    id="selectYear"
                    value={selectYear}
                    onChange={handleSelectChangeYear}
                  >
                    {Array.from({
                      length: new Date().getFullYear() - 2022 + 1,
                    }).map((_, index) => (
                      <option value={new Date().getFullYear() - index}>
                        {new Date().getFullYear() - index}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              <Col xs="10">
                <CardBody>
                  <BootstrapTable
                    data={dataActivities}
                    pagination={true}
                    options={options}
                    version="4"
                    className="table table-striped"
                  >
                    <TableHeaderColumn
                      dataField="_id"
                      dataFormat={rowindex}
                      width="20"
                      isKey
                    >
                      Index
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="imageSrc"
                      dataFormat={imageFormatter}
                      width="50"
                    >
                      ภาพปก
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="title"
                      dataFormat={titleFormatter}
                      width="100"
                    >
                      ชื่อหัวข้อ
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="title"
                      dataFormat={descriptionFormatter}
                      width="150"
                    >
                      รายละเอียด
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="_id"
                      width="50"
                      dataFormat={viewGallery}
                    >
                      ดูแกลลอรี่
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      dataField="_id"
                      width="50"
                      dataFormat={editActivities}
                    >
                      แก้ไข
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      dataField="_id"
                      width="50"
                      dataFormat={deletebutton}
                    >
                      Delete
                    </TableHeaderColumn>
                  </BootstrapTable>
                </CardBody>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <Modal isOpen={modal} toggle={toggle} className={`modal-galley-job`}>
        <ModalBody style={{ width: 'auto' }}>
          <Gallery images={imageGallery} onClick={handleClick} />
          {!!currentImage && (
            <Lightbox
              reactModalStyle={{ overlay: { zIndex: 9999 } }}
              mainSrc={currentImage?.src}
              mainSrcThumbnail={currentImage?.src}
              nextSrc={nextImage?.src}
              nextSrcThumbnail={nextImage?.src}
              prevSrc={prevImage?.src}
              prevSrcThumbnail={prevImage?.src}
              onCloseRequest={handleClose}
              onMovePrevRequest={handleMovePrev}
              onMoveNextRequest={handleMoveNext}
            />
          )}
        </ModalBody>
      </Modal>
    </Row>
  )
}

export default ActivitiesSection
