import React, { useRef, useEffect, useState } from 'react'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
} from 'reactstrap'
import endpoint from '../../config/config-api'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
const axios = Axios.create({ withCredentials: true })
const FormHomaPage = (props) => {
  const [data, setData] = useState({
    title: '',
    description: EditorState.createEmpty(),
    imageSrc: undefined,
    file: undefined,
    rank: '',
  })

  const [validate, setValidate] = useState(undefined)
  const [id] = useState(props.match.params.id)

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    if (id) {
      const response = await axios.get(
        `${endpoint.apiTest}/v1.1/job/cms/testimonial/${id}`,
      )
      if (response.status === 200) {
        const newData = response.data.data
        const description = newData.description
        setData({
          ...newData,
          description: FormatSetEditor(description),
        })
      }
    } else {
      const editor = FormatSetEditor('')
      setData({
        ...data,
        description: editor,
      })
    }
  }

  const FormatSetEditor = (data) => {
    const contentBlockhtml = htmlToDraft(data)

    const contentState = ContentState.createFromBlockArray(
      contentBlockhtml.contentBlocks,
    )

    const editorStateTemplate = EditorState.createWithContent(contentState)

    return editorStateTemplate
  }

  const EditJobDetail = (editor) => {
    setData({
      ...data,
      description: editor,
    })
  }

  const setValue = (name) => (event) => {
    switch (name) {
      case 'rank':
        setData({
          ...data,
          rank: event.target.value,
        })

        break
      case 'title':
        setData({
          ...data,
          title: event.target.value,
        })

        break

      // case 'description':
      //   setData({
      //     ...data,
      //     description: event.target.value,
      //   })

      //   break

      case 'file':
        let { type, size } =
          event.target.files[0] !== undefined && event.target.files[0]
        ;(async (value = event.target.files[0]) => {
          const check = await checkSizeImage(event.target.files[0])
          if (!check) {
            Swal.fire({
              icon: 'error',
              title: 'ขนาดรูปภาพเกิน 1280 x 720',
              text: '  กรุณาเลือกรูปภาพใหม่',
            })
            setData({
              ...data,
              imageSrc: '',
              file: '',
            })
            setValidate({
              ...validate,
              file: 'กรุณาแนบไฟล์ตามขนาดที่กำหนด',
            })
          } else {
            if (['image/jpg', 'image/jpeg', 'image/png'].includes(type)) {
              setData({
                ...data,
                imageSrc: value.name,
                file: value,
              })
              setValidate({
                ...validate,
                file: '',
              })
            } else {
              setValidate({
                ...validate,
                file: 'กรุณาแนบไฟล์เป็น .jpg .jpeg .png เท่านั้น',
              })
            }
          }
        })()
        break
      default:
        break
    }
  }

  const checkSizeImage = (file) => {
    return new Promise((resolve) => {
      const url = URL.createObjectURL(file)
      let img = new Image()

      img.onload = function () {
        if (this.width < 1280 && this.height <= 1200) {
          resolve(true)
        } else {
          resolve(false)
        }
      }

      img.src = url
    })
  }

  const onCheckValidate = (req) => {
    if (!req.imageSrc) {
      setValidate({
        file: !req.imageSrc && 'กรุณาเลือกไฟล์รูปภาพ',
      })
      return true
    } else if (!req.title) {
      setValidate({
        title: !req.title && 'กรุณากรอกชื่อ',
      })
      return true
    } else if (!req.rank) {
      setValidate({
        rank: !req.rank && 'กรุณากรอกตำแหน่ง',
      })
      return true
    } else if (!req.description) {
      setValidate({
        description: !req.description && 'กรุณากรอกบทความสัมภาษณ์',
      })
      return true
    } else {
      setValidate(undefined)
      return false
    }
  }

  const handleSubmit = async () => {
    const newData = {
      ...data,
      description: draftToHtml(
        convertToRaw(data.description.getCurrentContent()),
      ),
    }

    const result = onCheckValidate(newData)
    if (!result) {
      const formData = new FormData()
      const arr = ['title', 'description', 'imageSrc', 'file', 'rank']

      for (let value of arr) {
        formData.append(value, newData[value])
      }

      if (id) {
        const result = await axios.put(
          `${endpoint.apiTest}/v1.1/job/cms/testimonial/update/${id}?upload=testimonial`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (result.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
          }).then((result) => {
            if (result.isConfirmed) {
              props.history.push('/EditHomePage')
            }
          })
        }
      } else {
        const result = await axios.post(
          `${endpoint.apiTest}/v1.1/job/cms/testimonial/create?upload=testimonial`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (result.status === 200) {
          if (result.data.count >= 10) {
            Swal.fire({
              icon: 'warning',
              title: 'บันทึกได้สูงสุด 10 บทสัมภาษณ์',
            }).then((result) => {
              if (result.isConfirmed) {
                props.history.push('/EditHomePage')
              }
            })
          } else {
            Swal.fire({
              icon: 'success',
              title: 'บันทึกข้อมูลสำเร็จ',
            }).then((result) => {
              if (result.isConfirmed) {
                props.history.push('/EditHomePage')
              }
            })
          }
        }
      }
    }
  }

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="card-body">
          <Row>
            <Col md={8}>
              <FormGroup>
                <Label for="exampleAddress">
                  ภาพปก: ขนาด 1280 x 1200 / นามสกุลไฟล์ .jpg, .png
                  {data.imageSrc !== '' ? data.imageSrc : `Example file input`}
                </Label>
                <Input
                  type="file"
                  name="image"
                  id="image"
                  accept="image/jpg, image/jpeg, image/png, video/mp4, video/quicktime"
                  onChange={setValue('file')}
                  style={{ cursor: 'pointer' }}
                />
                {validate && validate.file && (
                  <Alert color="danger">{validate.file}</Alert>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="exampleAddress">ชื่อ</Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  className={
                    validate && validate.title
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                  value={data.title}
                  onChange={setValue('title')}
                />
                {validate && validate.title && (
                  <Alert color="danger">{validate.title}</Alert>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleAddress">ตำแหน่ง</Label>
                <Input
                  type="textarea"
                  name="rank"
                  id="rank"
                  className={
                    validate && validate.rank
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                  value={data.rank}
                  onChange={setValue('rank')}
                />
                {validate && validate.rank && (
                  <Alert color="danger">{validate.rank}</Alert>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup height="500px">
                <Label for="exampleAddress">บทความสัมภาษณ์</Label>

                <Editor
                  editorState={data.description}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={EditJobDetail}
                />
                {validate && validate.description && (
                  <Alert color="danger">{validate.description}</Alert>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <div className="btn">
              <Button onClick={() => handleSubmit()}>บันทึก</Button>
            </div>

            <div className="btn">
              <Button onClick={() => props.history.push('/EditHomePage')}>
                ย้อนกลับ
              </Button>
            </div>
          </Row>
        </div>
      </Form>
    </Container>
  )
}

export default FormHomaPage
