import React from 'react'
import { Link } from 'react-router-dom'

export default function Sidebar() {
  return (
    <ul
      className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      {/* Sidebar - Brand */}
      <Link
        to="/dashboard"
        className="sidebar-brand d-flex align-items-center justify-content-center"
      >
        <div className="sidebar-brand-text mx-3">
          CMS JOB <sup>V.5</sup>
        </div>
      </Link>
      {/* Divider */}
      {/* <hr className="sidebar-divider my-0" /> */}
      {/* Nav Item - Dashboard */}
      {/* <li className="nav-item">
        <Link to="/" className="nav-link">
          <i className="fas fa-fw fa-tachometer-alt" />
          <span>หน้าหลัก</span>
        </Link>
      </li> */}
      <li className="nav-item">
        <Link to="/EditHomePage" className="nav-link">
          <i className="fas fa-fw fa-tachometer-alt" />
          <span>ข้อมูลหน้า Homapege</span>
        </Link>
      </li>
      <hr className="sidebar-divider d-none d-md-block" />
      <li className="nav-item">
        <Link to="/panorama" className="nav-link">
          <i className="fas fa-fw fa fa-bolt" />
          <span>panorama</span>
          
        </Link>
      </li>
      <hr className="sidebar-divider d-none d-md-block" />

      <li className="nav-item">
        <Link to="/data" className="nav-link">
          <i className="fas fa-users-cog" />
          <span>ข้อมูลผู้สมัครงาน</span>
        </Link>
      </li>
      <hr className="sidebar-divider d-none d-md-block" />

      <hr className="sidebar-divider d-none d-md-block" />
      <li className="nav-item">
        <Link to="/announcement" className="nav-link">
          <i className="fas fa-fw fa fa-bolt" />
          <span>ประกาศสมัครงาน</span>
        </Link>
      </li>

      <hr className="sidebar-divider d-none d-md-block" />
      <li className="nav-item">
        <Link to="/department" className="nav-link">
          <i className="fas fa-fw fa fa-bolt" />
          <span>เพิ่มสายงาน</span>
        </Link>
      </li>

    

      {/* <hr className="sidebar-divider d-none d-md-block" /> */}
      {/* <li className="nav-item">
        <Link to="/contact" className="nav-link">
          <i className="fas fa-fw fa fa-bolt" />
          <span>สังกัด</span>
        </Link>
      </li> */}

      {/* <hr className="sidebar-divider d-none d-md-block" />
      <li className="nav-item">
        <Link to="/joinus" className="nav-link">
          <i className="fas fa-fw fa fa-bolt" />
          <span>ร่วมงานกับเรา</span>
        </Link>
      </li> */}

      <hr className="sidebar-divider d-none d-md-block" />
      <li className="nav-item">
        <Link to="/template" className="nav-link">
          <i className="fas fa-fw fa fa-bolt" />
          <span>template mail</span>
        </Link>
      </li>

      {/* Sidebar Toggler (Sidebar) */}
      {/* <div className="text-center d-none d-md-inline">
					<button className="rounded-circle border-0" id="sidebarToggle" />
				</div> */}
    </ul>
  )
}
