import React, { useRef, useEffect, useState } from 'react'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
} from 'reactstrap'
import endpoint from '../../config/config-api'
import Axios from 'axios'
import Swal from 'sweetalert2'
import slate from '@react-page/plugins-slate'
import background from '@react-page/plugins-background'
// The editor core
import { BottomToolbar, Value } from '@react-page/editor'
import Editor from '@react-page/editor'
import { imagePlugin } from '../../lib/plugin/imageIndex'
import PageLayout from '../../component/PageLayout'
import '@react-page/editor/lib/index.css'
import '@react-page/plugins-image/lib/index.css'
import '@react-page/plugins-slate/lib/index.css'
import '@react-page/plugins-background/lib/index.css'
import customToolbar from '../../lib/plugin/reactPage/customToolbar'
import data_default from './default_data/data.json'
import { staticPageController } from './Services'
import { templateMailController } from '../Template/Service'

const axios = Axios.create({ withCredentials: true })
const FormPanorama = (props) => {
  const [value, setValue] = useState(data_default)
  const [id] = useState(props.match.params.id)
  const [data, setData] = useState({
    slag_name: '',
    body: data_default,
    publish: false,
  })
  const [validate, setValidate] = useState(undefined)

  const uploadImage = (url) => (file, reportProgress) => {
    return new Promise(async (resolve, reject) => {
      let counter = 0  
      const formData = new FormData()
      formData.append('file', file, file.name)
      const target_file = formData
    
      const response = await templateMailController().uploadFile(
        target_file,
        'static_page',
      )
      if (response.status === 200) {
        let myFile = response.data.data
        resolve({ url: myFile.path })
      }
    })
  }

  const cellPlugins = [
    slate((def) => ({
      ...def,
      plugins: {
        ...def.plugins,
        yourCustomNamespace: {
          myCustomPlugin: customToolbar,
        },
      },
    })),
    imagePlugin({
      imageUpload: uploadImage(
        'https://upload.wikimedia.org/wikipedia/commons/a/a8/Derbys_Peter_Pan_peanut_butter_sample_blikje%2C_foto3.JPG',
      ),
    }),
    background({
      imageUpload: uploadImage(
        'https://upload.wikimedia.org/wikipedia/commons/a/a8/Derbys_Peter_Pan_peanut_butter_sample_blikje%2C_foto3.JPG',
      ),
    }),
  ]

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    if (id) {
      const response = await staticPageController().getById(id)
      if (response.data.status === 200) {
        const resData = response.data.data
        setData(resData)
      }
    }
  }

  const onSubmit = async () => {
    if (!data.slag_name) {
      setValidate({ title: 'กรุณาระบุ slag_name' })
    } else {
      const newData = {
        body: value,
        slag_name: data.slag_name,
        publish: data.publish,
      }

      if (id) {
        const response = await staticPageController().updateById(id, newData)
        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
          }).then((result) => {
            if (result.isConfirmed) {
              props.history.push('/joinus')
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data.message,
          })
        }
      } else {
        const response = await staticPageController().create(newData)
        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
          }).then((result) => {
            if (result.isConfirmed) {
              props.history.push('/joinus')
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data.message,
          })
        }
      }
    }
  }

  return (
    <div>
      <Container>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleAddress">Slagname</Label>
              <Input
                type="text"
                name="title"
                id="title"
                className={
                  validate && validate.title
                    ? 'is-invalid form-control'
                    : 'form-control'
                }
                autoComplete="off"
                value={data.slag_name}
                onChange={(event) => {
                  setData({ ...data, slag_name: event.target.value })
                  setValidate({ title: '' })
                }}
              />
              {validate && validate.title && (
                <Alert color="danger">{validate.title}</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs="6" sm="4">
            <FormGroup tag="fieldset">
              Publish
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="publish"
                    checked={data.publish}
                    onChange={() => setData({ ...data, publish: true })}
                  />
                  เปิด
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="publish"
                    checked={!data.publish}
                    onChange={() => setData({ ...data, publish: false })}
                  />
                  ปิด
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Button onClick={() => onSubmit()}>Submit</Button>
      </Container>

      <PageLayout>
        <Editor
          cellPlugins={cellPlugins}
          value={data.body}
          onChange={setValue}
          cellSpacing={12}
          components={{
            BottomToolbar: BottomToolbar,
          }}
        />
      </PageLayout>
    </div>
  )
}

export default FormPanorama
