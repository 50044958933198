import React, { useRef, useEffect, useState } from 'react'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
} from 'reactstrap'
import endpoint from '../../config/config-api'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { ReactMultiEmail } from 'react-multi-email'
const axios = Axios.create({ withCredentials: true })

const FormContactMail = (props) => {
  const [data, setData] = useState({
    contactName: '',
    emails: [],
  })

  const [validate, setValidate] = useState(undefined)
  const [id] = useState(props.match.params.id)

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    if (id) {
      const response = await axios.get(
        `${endpoint.apiTest}/v1.1/job/cms/contactMail/${id}`,
      )
      if (response.status === 200) {
        const newData = response.data.response
        setData(newData)
      }
    } else {
      setData(data)
    }
  }

  const setValue = (name) => (event) => {
    switch (name) {
      case 'title':
        setData({
          ...data,
          contactName: event.target.value,
        })

        break

      case 'emails':
        setData({
          ...data,
          emails: [...event],
        })
        setValidate(undefined)
        break

      default:
        break
    }
  }

  const onCheckValidate = (data) => {
      if(data.emails.length < 1){
        setValidate({
          emails : 'กรุณากรอก email ภายในสังกัดอย่างน้อย 1 อัน'
        })
        return true
      }else{
        setValidate(undefined)
      return false
      }
  }

  const handleSubmit = async () => {
    const check = onCheckValidate(data)

    if(!check){
      if (id) {
        const result = await axios.put(
          `${endpoint.apiTest}/v1.1/job/cms/contactMail/${id}`,
          data,
        )
  
        if (result.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
          }).then((result) => {
            if (result.isConfirmed) {
              props.history.push('/contact')
            }
          })
        }
      } else {
        const result = await axios.post(
          `${endpoint.apiTest}/v1.1/job/cms/contactMail`,
          data,
        )
  
        if (result.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
          }).then((result) => {
            if (result.isConfirmed) {
              props.history.push('/contact')
            }
          })
        }
      }
    }
   
  }

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleAddress">Title</Label>
              <Input
                type="text"
                name="title"
                id="title"
                className={
                  validate && validate.contactName
                    ? 'is-invalid form-control'
                    : 'form-control'
                }
                autoComplete="off"
                value={data.contactName}
                onChange={setValue('title')}
              />
              {validate && validate.title && (
                <Alert color="danger">{validate.title}</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleAddress">Emails</Label>
              <ReactMultiEmail
                placeholder=""
                className={validate && validate.emails ? 'is-invalidate-input-email' : 'custom-input-email' }
                emails={data.emails}
                onChange={setValue('emails')}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      {email}
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  )
                }}
              />
              {validate && validate.emails && (
                <Alert color="danger">{validate.emails}</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <div className="btn">
            <Button onClick={() => handleSubmit()}>บันทึก</Button>
          </div>
          <div className="btn">
            <Button onClick={() => props.history.push('/contact')}>
              ย้อนกลับ
            </Button>
          </div>
        </Row>
      </Form>
    </Container>
  )
}

export default FormContactMail
